import { createRouter, createWebHistory } from 'vue-router'


const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/HomeView.vue')
	},
	{
		path: '/locations',
		name: 'Location',
		component: () => import('../views/LocationsView.vue')
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router;

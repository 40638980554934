<template>
  <router-view></router-view>
</template>

<style>
@import"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&family=Alex+Brush&display=swap";.content-box .content-box-header {
	display: flex;
	justify-content: space-between
}

*{
  padding: 0;
  margin: 0;
}

html {
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
	font-feature-settings: normal;
	font-variation-settings: normal;
	white-space: nowrap;
}

li{
  list-style: none;
}

#loader {
	text-align: center;
	position: fixed;
	z-index: 3;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background-color: rgba(255, 255, 255, 0.95);
}

#loader {
	opacity: 1;
	visibility: visible;
}

#loader div {
	display: inline-block;
	width: 50px;
	height: 50px;
	vertical-align: middle;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

#loader div:before {
	content: '';
	width: 26px;
	height: 26px;
	display: block;
	margin: 8px auto 0;
	-webkit-border-radius: 20px;
	border-radius: 20px;
	-webkit-animation: loading 1.0s linear forwards infinite;
	animation: loading 1.0s linear forwards infinite;
	border-top: 4px solid #e0f2fe;
	border-right: 4px solid #f87171;
	border-bottom: 4px solid #ffa900;
	border-left: 4px solid #fef3c7;
}

#loader:after {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

@keyframes loading {
	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes loading {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
</style>